import { types } from "mobx-state-tree"
import User from "../../models/user/User";
import * as actions from './teamMemberStoreActions'
import * as views from './teamMemberStoreViews'

const SystemSettingsStore = types
  .model('SystemSettingsStore', {
    CLOSING_SOON_TRESHOLD: types.maybeNull(types.string),
    DEFAULT_CLOSE_TIME: types.maybeNull(types.string),
    DEFAULT_DELIVERY_COMMISSION: types.maybeNull(types.string),
    DEFAULT_OPEN_TIME: types.maybeNull(types.string),
    DEFAULT_PICKUP_COMMISSION: types.maybeNull(types.string),
    DEFAULT_PREPARATION_TIME: types.maybeNull(types.string),
    DELIVERY_RATE_METERS_MULTIPLIER: types.maybeNull(types.string),
    DELIVERY_RATE_DOLLARS_MULTIPLIER: types.maybeNull(types.string),
    LAST_SUPPORTED_VERSION: types.maybeNull(types.string),
    TOTAL_SERVICE_TIME: types.maybeNull(types.string),
    NO_ORDER: types.maybeNull(types.string),
    EDITORS_PICKS_TITLE: types.maybeNull(types.string),
    BASED_ON_ORDERS_TITLE: types.maybeNull(types.string),
    BANNER_ON: types.maybeNull(types.string),
    BANNER_IMAGE: types.maybeNull(types.string),
    BANNER_TITLE: types.maybeNull(types.string),
    BANNER_SUBTITLE: types.maybeNull(types.string),

    CLOSING_SOON_TRESHOLD_loading: false,
    DEFAULT_CLOSE_TIME_loading: false,
    DEFAULT_DELIVERY_COMMISSION_loading: false,
    DEFAULT_OPEN_TIME_loading: false,
    DEFAULT_PICKUP_COMMISSION_loading: false,
    DEFAULT_PREPARATION_TIME_loading: false,
    DELIVERY_RATE_METERS_MULTIPLIER_loading: false,
    DELIVERY_RATE_DOLLARS_MULTIPLIER_loading: false,
    LAST_SUPPORTED_VERSION_loading: false,
    TOTAL_SERVICE_TIME_loading: false,
    NO_ORDER_loading: false,
    EDITORS_PICKS_TITLE_loading: false,
    BASED_ON_ORDERS_TITLE_loading: false,
    BANNER_ON_loading: false,
    BANNER_IMAGE_loading: false,
    BANNER_TITLE_loading: false,
    BANNER_SUBTITLE_loading: false
  })
  .views(views.systemSettingsStoreViews)
  .actions(actions.systemSettingsStoreActions)

export default SystemSettingsStore